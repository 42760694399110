import {
  GETDASHBOARDINFO,
  GETOVERVIEWDASHBOARD,
  GETCATEGORYSPENDING,
  GETINVOICETRACKEROVERVIEW,
  GETTOPSUPPLIER,
  GETMONTHLYEXPENSE,
  GETYEARLYEXPENSE,
} from "src/modules/dashboard/service/DashboardActionType";
import { GETPRODUCTBYSUPPLIERSTATISTIQUE } from "src/modules/categorization/product/service/ProductActionType";
const initialState = {
  dashboardInfo: {},
  dashboardOverview: {},
  categorySpending: [],
  invoicetracker: [],
  topFiveSupplier: [],
  monthlyExpense: {},
  yearlyExpense: {},
  productBySupplierStatistique: {},
};

export default function DashboardReducer(state = initialState, action) {
  switch (action.type) {
    case GETDASHBOARDINFO:
      return {
        ...state,
        dashboardInfo: action.payload.data,
      };
    case GETOVERVIEWDASHBOARD:
      return {
        ...state,
        dashboardOverview: action.payload.data,
      };

    case GETCATEGORYSPENDING:
      return {
        ...state,
        categorySpending: action.payload.data,
      };

    case GETINVOICETRACKEROVERVIEW:
      return {
        ...state,
        invoicetracker: action.payload.data,
      };

    case GETTOPSUPPLIER:
      return {
        ...state,
        topFiveSupplier: action.payload.data,
      };

    case GETPRODUCTBYSUPPLIERSTATISTIQUE:
      return {
        ...state,
        productBySupplierStatistique: action.payload.data,
      };

    case GETMONTHLYEXPENSE:
      return {
        ...state,
        monthlyExpense: action.payload.data,
      };

    case GETYEARLYEXPENSE:
      return {
        ...state,
        yearlyExpense: action.payload.data,
      };

    default: {
      return state;
    }
  }
}
