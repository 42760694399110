import dayjs from "dayjs";
// Day.js plugin for advanced formatting
import customParseFormat from "dayjs/plugin/customParseFormat";
import "dayjs/locale/it"; // Load Italian locale
import relativeTime from "dayjs/plugin/relativeTime"; // Load relative time plugin
import moment from "moment";

export const formatToDate = (inputDate) => {
  dayjs.extend(customParseFormat);

  // Parse and format the date
  const date = dayjs(inputDate, "ddd, DD MMM YYYY HH:mm:ss [GMT]");

  // Format the date to 'YYYY-MM-DD'
  return date.format("YYYY-MM-DD");
};

export const formatDateAgo = (dateToFormat) => {
  // Extend dayjs with relative time plugin
  dayjs.extend(relativeTime);
  // Set locale to Italian
  dayjs.locale("it");
  // Format the provided date string into a human-readable relative time
  return dayjs(dateToFormat).fromNow();
};

export const formatItalianDate = (inputDate) => {
  // Set locale to Italian
  dayjs.locale("it");

  return dayjs(inputDate).format("dddd MMMM D, YYYY H:mm");
};

export const convertTimestamp = (timestamp) => {
  const millisecondsDifference = Math.abs(moment().diff(moment(timestamp)));

  if (millisecondsDifference < 60000) {
    // Less than 1 minute
    return `${Math.floor(millisecondsDifference / 1000)} seconds`;
  } else if (millisecondsDifference < 3600000) {
    // Less than 1 hour
    return `${Math.floor(millisecondsDifference / 60000)} minutes`;
  } else if (millisecondsDifference < 86400000) {
    // Less than 1 day
    return `${Math.floor(millisecondsDifference / 3600000)} hours`;
  } else {
    // More than 1 day
    return `${Math.floor(millisecondsDifference / 86400000)} days`;
  }
};

export const setFromDateIfNull = (from) => {
  // Check if 'from' is null, if so, set it to '1970-01-01'
  return from ? dayjs(from).format("YYYY-MM-DD") : "1970-01-01";
};

export const setToDateIfNull = (to) => {
  // Set 'to' date to today's date if it's null
  return to ? dayjs(to).format("YYYY-MM-DD") : dayjs().format("YYYY-MM-DD");
};

export const formatDateToSentence = (dateToFormat) => {
  return dayjs(dateToFormat).locale("it").format("DD MMMM YYYY");
};

export const formatDateItalianShort = (inputDate) => {
  // Parse the input date using dayjs
  const parsedDate = dayjs(inputDate);

  // Format the date as "DD MMM" (e.g., "28 AVR")
  const formattedDate = parsedDate.format("DD MMM hh:mm");

  return formattedDate;
};


export  const parseToValidDate = (dateString) => {
  // Check if dateString is null or undefined
  if (!dateString) {
    return null; // Return null if no valid date is found
  }

  // Parse the date string using dayjs
  const parsedDate = dayjs(dateString);

  // Check if the parsed date is valid
  if (!parsedDate.isValid()) {
    return null; // Return null if the date is invalid
  }

  // Return the date in 'YYYY-MM-DD' format
  return parsedDate.format('YYYY-MM-DD');
};
