export const environment = {
    //production: false,
 //   baseUrl: "http://localhost:8084/api/v1",


    //Production environment
    production: true,
    baseUrl: "https://horecacode.it:8444/api/v1",

    // production: false,
    // baseUrl: "https://horecacode-57b2a6aa10e8.herokuapp.com/api/v1",
};
